import { render, staticRenderFns } from "./UploadMaterias.vue?vue&type=template&id=3d15443e"
import script from "./upload-materias.js?vue&type=script&lang=js&external"
export * from "./upload-materias.js?vue&type=script&lang=js&external"
import style0 from "./UploadMaterias.vue?vue&type=style&index=0&id=3d15443e&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/jenkins_workspace/workspace/vuejs-tb-train-ontraining-web-sim/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d15443e')) {
      api.createRecord('3d15443e', component.options)
    } else {
      api.reload('3d15443e', component.options)
    }
    module.hot.accept("./UploadMaterias.vue?vue&type=template&id=3d15443e", function () {
      api.rerender('3d15443e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/upload-materias/UploadMaterias.vue"
export default component.exports