import http from '@tibi/http'
import axios from 'axios'
// import tencentVod from '../../util/TencentVod'
import tencentVod from '@tibi/tencent-vod'
import {HudGlobal} from "../../util/HudGlobal";


// import TcVod from 'vod-js-sdk-v6'
// function getSignature() {
//     return http.get('/api/upload/tentent/sign').then(response => {
//         console.log("SIGN ======= " + response.data.data);
//             return response.data.data ? response.data.data : response.data;
//     })
// }

export default {
    name: 'UploadMaterias',
    data() {
        return {
            // uploaderInfos: [],
            uploadForm: {
                pass: ''
            },
            // 计划数据
            planData: {},
            rules2: [],
            dialogImageUrl: '',
            dialogVisible: false,
            fileList: []
        }
    },
    created: function () {
        // this.tcVod = new TcVod({
        //     getSignature: getSignature
        // })

        let query = this.$route.query;
        console.log('参数:', query)
        this.planData = query;
    },
    methods: {
        /*
        * 覆盖默认上传动作
        * */
        fnUploadRequest(option) {
            // this.$refs.vExampleFile.click()
            this.uploadFiles(option)

        },
        //index demo
        // vExampleUpload() {
        //
        //     var self = this;
        //     var videoFile = this.$refs.vExampleFile.files[0]
        //     console.log('2:',videoFile)
        //
        //     var uploader = this.tcVod.upload({
        //         videoFile: videoFile,
        //     })
        //     uploader.on('video_progress', function (info) {
        //         console.log('999')
        //         uploaderInfo.progress = info.percent;
        //     })
        //     uploader.on('video_upload', function (info) {
        //         console.log('111')
        //         uploaderInfo.isVideoUploadSuccess = true;
        //     })
        //
        //     console.log(uploader, 'uploader')
        //
        //     var uploaderInfo = {
        //         videoInfo: uploader.videoInfo,
        //         isVideoUploadSuccess: false,
        //         isVideoUploadCancel: false,
        //         progress: 0,
        //         fileId: '',
        //         videoUrl: '',
        //         cancel: function() {
        //             uploaderInfo.isVideoUploadCancel = true;
        //             uploader.cancel()
        //         },
        //     }
        //
        //     this.uploaderInfos.push(uploaderInfo)
        //
        //     uploader.done().then(function(doneResult) {
        //         console.log('doneResult', doneResult)
        //
        //         uploaderInfo.fileId = doneResult.fileId;
        //
        //         return getAntiLeechUrl(doneResult.video.url);
        //     }).then(function (videoUrl) {
        //         uploaderInfo.videoUrl = videoUrl
        //         self.$refs.vExample.reset();
        //     })
        // },
        /*
        * 上传文件
        * */
        uploadFiles(option) {
            console.log('33333')
            const videoFile = option.file
            console.log('file:', videoFile)

            // tencentVod.upload(videoFile);
            tencentVod.upload(videoFile, {
                url: "http://192.168.18.163:8626/api/upload/tentent/sign",
                done: function (data) {
                    console.log("上传完成...")
                    console.log(data);
                },
                progress: function (data) {
                    console.log("上传进度:" + JSON.stringify(data))
                }
            })

            // if (videoFile) {
            //     console.log("开始上传视频");
            //     const uploader = this.tcVod.upload({
            //         videoFile: videoFile, // 视频，类型为 File
            //     })
            //     uploader.on('video_progress', function(info) {
            //         console.log("上传进度:" + info.percent) // 进度
            //     })
            //
            //     uploader.done().then(function (doneResult) {
            //         // deal with doneResult
            //         console.log("上传完成");
            //         console.log(doneResult)
            //     })
            // }
        },
        /*
        * 文件超出限制
        * */
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，
            共选择了 ${files.length + fileList.length} 个文件`);
        },
        /*
        * 移除
        * */
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        /*
        * 提交
        * */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        /*
        * 重置
        * */
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        /*
        * 退出, 返回到计划列表页面
        * */
        logoutDo() {
            HudGlobal.showAlertConfirmMessage("是否返回计划列表").then(() => {
                this.$router.push({path: '/'});
            })
        }
    }
}
