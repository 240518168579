var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container upload-materias" },
    [
      _c("div", { staticClass: "plan-info" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.planData.planName))
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "计划周期： " +
                _vm._s(_vm.planData.startTime) +
                " 至 " +
                _vm._s(_vm.planData.endTime)
            )
          ]),
          _c("li", [_vm._v("所属组织： " + _vm._s(_vm.planData.orgName))]),
          _c("li", [_vm._v("计划类型： " + _vm._s(_vm.planData.projectName))]),
          _c("li", [
            _vm._v(
              "培训状态： " + _vm._s(_vm._f("planState")(_vm.planData.state))
            )
          ])
        ])
      ]),
      _c(
        "el-form",
        {
          ref: "uploadForm",
          staticClass: "upload-form",
          attrs: {
            model: _vm.uploadForm,
            "status-icon": "",
            "label-position": "top"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "file-upload",
              attrs: { label: "上传文件", prop: "pass" }
            },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "",
                    "http-request": _vm.fnUploadRequest,
                    "on-remove": _vm.handleRemove,
                    multiple: "",
                    limit: 3,
                    "on-exceed": _vm.handleExceed,
                    "file-list": _vm.fileList
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.uploadFiles }
                    },
                    [_vm._v("上传文件")]
                  ),
                  _c("p", [
                    _vm._v("文件格式：视频、音频、ppt、word、excel、pdf")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "photo-upload",
              attrs: { label: "上传图片", prop: "pass" }
            },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "https://jsonplaceholder.typicode.com/posts/",
                    "list-type": "picture-card",
                    "on-preview": _vm.handlePictureCardPreview,
                    "on-remove": _vm.handleRemove
                  }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "comments", attrs: { label: "备注", prop: "pass" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", height: "160" },
                model: {
                  value: _vm.uploadForm.pass,
                  callback: function($$v) {
                    _vm.$set(_vm.uploadForm, "pass", $$v)
                  },
                  expression: "uploadForm.pass"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm("ruleForm")
                    }
                  }
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.resetForm("ruleForm")
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }